var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modalDetails",attrs:{"id":"modal-select2","title":"Informations supplémentaires","ok-title":"Enregistrer","cancel-title":"Annuler","cancel-variant":"outline-secondary","size":"lg","no-close-on-backdrop":""},on:{"ok":function($event){$event.preventDefault();return _vm.submitReservationDetails.apply(null, arguments)}}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[(!_vm.isTransat)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h1',{staticClass:"section-label mx-0 mb-2"},[_vm._v(" Assurances ")])])],1):_vm._e(),(!_vm.isTransat)?_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Possédez-vous une assurance voyage?","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Possédez-vous une assurance voyage?","label-for":"Possédez-vous une assurance voyage?"}},[_c('b-form-radio-group',{staticClass:"medical-radios",class:{ 'is-invalid': errors.length > 0 },attrs:{"options":_vm.yesNoOptions},model:{value:(_vm.currentReservation.has_trip_insurance),callback:function ($$v) {_vm.$set(_vm.currentReservation, "has_trip_insurance", $$v)},expression:"currentReservation.has_trip_insurance"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4213864066)})],1),(_vm.currentReservation.has_trip_insurance)?_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Type d'assurance","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Sélectionner le type d’assurance que vous possédez","label-for":"Sélectionner le type d’assurance que vous possédez"}},[_c('v-select',{class:{ 'is-invalid': errors.length > 0 },attrs:{"id":"group-type","multiple":"","selectable":function (option) { return ! option.value.includes('select_value'); },"label":"text","reduce":function (text) { return text.value; },"options":_vm.insurance_types},model:{value:(_vm.currentReservation.insurance_types),callback:function ($$v) {_vm.$set(_vm.currentReservation, "insurance_types", $$v)},expression:"currentReservation.insurance_types"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4219184454)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Compagnie d'assurance","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Indiquer votre compagnie d’assurance voyage","label-for":"Indiquer votre compagnie d’assurance voyage"}},[_c('b-form-input',{class:{ 'is-invalid': errors.length > 0 },model:{value:(_vm.currentReservation.insurer_name),callback:function ($$v) {_vm.$set(_vm.currentReservation, "insurer_name", $$v)},expression:"currentReservation.insurer_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,660178388)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Numéro de police d'assurance","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Indiquer votre numéro de police d'assurance voyage","label-for":"Indiquer votre numéro de police d'assurance voyage"}},[_c('b-form-input',{class:{ 'is-invalid': errors.length > 0 },model:{value:(_vm.currentReservation.insurance_contract_number),callback:function ($$v) {_vm.$set(_vm.currentReservation, "insurance_contract_number", $$v)},expression:"currentReservation.insurance_contract_number"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1204806883)})],1)],1)],1):_vm._e()],1):_vm._e(),(!_vm.isTransat)?_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"numéro de membre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Possédez-vous un numéro de membre ou carte fidélité avec la compagnie aérienne, l’hôtel ou la compagnie de croisière?","label-for":"Possédez-vous un numéro de membre ou carte fidélité avec la compagnie aérienne, l’hôtel ou la compagnie de croisière?"}},[_c('b-form-radio-group',{staticClass:"medical-radios",class:{ 'is-invalid': errors.length > 0 },attrs:{"options":_vm.yesNoOptions},model:{value:(_vm.currentReservation.has_membership),callback:function ($$v) {_vm.$set(_vm.currentReservation, "has_membership", $$v)},expression:"currentReservation.has_membership"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2008608042)})],1),(_vm.currentReservation.has_membership)?_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"numéro de membre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Numéro de membre","label-for":"Numéro de membre"}},[_c('b-form-input',{class:{ 'is-invalid': errors.length > 0 },model:{value:(_vm.currentReservation.membership_number),callback:function ($$v) {_vm.$set(_vm.currentReservation, "membership_number", $$v)},expression:"currentReservation.membership_number"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2990106886)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Nom de la compagnie","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nom de la compagnie associée au numéro","label-for":"Nom de la compagnie associée au numéro"}},[_c('b-form-input',{class:{ 'is-invalid': errors.length > 0 },model:{value:(_vm.currentReservation.membership_company),callback:function ($$v) {_vm.$set(_vm.currentReservation, "membership_company", $$v)},expression:"currentReservation.membership_company"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,523256738)})],1)],1)],1):_vm._e()],1):_vm._e(),(!_vm.isTransat)?_c('hr',{staticClass:"my-2"}):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Informations supplémentaires","label-for":"extra-information"}},[_c('b-form-textarea',{attrs:{"size":"sm"},model:{value:(_vm.extra_information),callback:function ($$v) {_vm.extra_information=$$v},expression:"extra_information"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }