<template>
    <b-modal
      id="modal-select2"
      title="Informations supplémentaires"
      ok-title="Enregistrer"
      cancel-title="Annuler"
      ref="modalDetails"
      cancel-variant="outline-secondary"
      size="lg"
      @ok.prevent='submitReservationDetails'
      no-close-on-backdrop
    >
      <validation-observer ref="simpleRules">
    <b-form>
        <b-row v-if="!isTransat">
          <b-col md="12">
            <h1 class="section-label mx-0 mb-2">
              Assurances
            </h1>
          </b-col>
        </b-row>

        <b-row  v-if="!isTransat">
          <b-col md="4">
            <validation-provider
              #default="{ errors }"
              name="Possédez-vous une assurance voyage?"
              rules="required"
            >
            <b-form-group
              label="Possédez-vous une assurance voyage?"
              label-for="Possédez-vous une assurance voyage?"

            >
              <b-form-radio-group
                v-model="currentReservation.has_trip_insurance"
                :options="yesNoOptions"
                class="medical-radios"
                :class="{ 'is-invalid': errors.length > 0 }"

              />
            </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="6" v-if="currentReservation.has_trip_insurance">
            <b-row>
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  name="Type d'assurance"
                  rules="required"
                >
              <b-form-group
                label="Sélectionner le type d’assurance que vous possédez"
                label-for="Sélectionner le type d’assurance que vous possédez"

              >
                <v-select
                  id="group-type"
                  v-model="currentReservation.insurance_types"
                  multiple
                  :selectable="option => ! option.value.includes('select_value')"
                  label="text"
                  :reduce="text => text.value"
                  :class="{ 'is-invalid': errors.length > 0 }"
                  :options="insurance_types"
                />
              </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>

              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  name="Compagnie d'assurance"
                  rules="required"
                >
              <b-form-group
                label="Indiquer votre compagnie d’assurance voyage"
                label-for="Indiquer votre compagnie d’assurance voyage"
              >
                <b-form-input
                  v-model="currentReservation.insurer_name"
                  :class="{ 'is-invalid': errors.length > 0 }"
                />
              </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  name="Numéro de police d'assurance"
                  rules="required"
                >
              <b-form-group
                label="Indiquer votre numéro de police d'assurance voyage"
                label-for="Indiquer votre numéro de police d'assurance voyage"

              >
                <b-form-input
                  v-model="currentReservation.insurance_contract_number"
                  :class="{ 'is-invalid': errors.length > 0 }"
                />
              </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
          </b-col>
        <!-- remove for GEC
          <b-col md="6" v-if="!reservation.has_trip_insurance">
            <b-row>
              <b-col md="12">
              <b-form-group
                label="Je désire recevoir une soumission d’assurance"
                label-for="Je désire recevoir une soumission d’assurance"

              >
              <b-form-radio-group
                v-model="reservation.wants_insurance_submission"
                :options="yesNoOptions"
                class="medical-radios"

              />
              </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          -->

        </b-row>
        <!-- remove for GEC

        <b-row class="mt-2">
          <b-col md="12">
            <b-form-group
              label="Désirez-vous une sélection de sièges?"
              label-for="Désirez-vous une sélection de sièges?"
            >
                <v-select
                  id="group-type"
                  :options="seatReservationsOptions"
                  v-model="reservation.seat_selection"
                />
            </b-form-group>
          </b-col>
        </b-row>
        <hr class="my-2">

        -->

        <b-row class="mt-2"  v-if="!isTransat">
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="numéro de membre"
              rules="required"
            >
            <b-form-group
              label="Possédez-vous un numéro de membre ou carte fidélité avec la compagnie aérienne, l’hôtel ou la compagnie de croisière?"
              label-for="Possédez-vous un numéro de membre ou carte fidélité avec la compagnie aérienne, l’hôtel ou la compagnie de croisière?"

            >
              <b-form-radio-group
                v-model="currentReservation.has_membership"
                :options="yesNoOptions"
                class="medical-radios"
                :class="{ 'is-invalid': errors.length > 0 }"

              />
            </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="6" v-if="currentReservation.has_membership">
            <b-row>
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  name="numéro de membre"
                  rules="required"
                >
              <b-form-group
                label="Numéro de membre"
                label-for="Numéro de membre"

              >
                <b-form-input
                  v-model="currentReservation.membership_number"
                  :class="{ 'is-invalid': errors.length > 0 }"

                />
              </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  name="Nom de la compagnie"
                  rules="required"
                >
              <b-form-group
                label="Nom de la compagnie associée au numéro"
                label-for="Nom de la compagnie associée au numéro"
              >
                <b-form-input
                  v-model="currentReservation.membership_company"
                  :class="{ 'is-invalid': errors.length > 0 }"
                />
              </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
          </b-col>

        </b-row>
        <hr class="my-2" v-if="!isTransat">
        <b-row>
          <b-col md="12">
            <b-form-group label="Informations supplémentaires" label-for="extra-information">
              <b-form-textarea size="sm" v-model="extra_information"></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      </validation-observer>
    </b-modal>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BCardText,BRow,BCol, BCard,BInputGroup,
  BFormCheckbox,
  BInputGroupPrepend,
  BFormRadio,
  BFormTextarea,
  BTabs,
  BFormFile,
  BFormCheckboxGroup,
  BTab,
  BFormInvalidFeedback,
  BFormRadioGroup,


} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import groupCreationConfig from '@core/data/groupCreationConfig.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
import {ValidationObserver, ValidationProvider} from "vee-validate";
import { ref, nextTick } from '@vue/composition-api'

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BCardText,
    BFormGroup,
    vSelect,
    BRow,
    BFormRadioGroup,
    BCol,
    BInputGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BTabs,
    BFormFile,
    BTab,
    BFormInvalidFeedback,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BFormRadio
    },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props:['currentReservation'],
  data() {
    return {
      isTransat: true,
      hasSecondName: false,
      groupCreationConfig,
      yesNoOptions: [{text:'oui',value:true},{text:'non',value:false}],
      seatReservationsOptions: [
        "La sélection de siège gratuite offerte par le groupe (aucune sélection de siège possible",
        "La sélection d’un siège précis dans l’avion (frais supplémentaire $)",
        "La sélection de siège avec plus d’espace (frais supplémentaire $$)"],
      insurance_types:[
        {text:"Médical" ,value: "MEDICAL"},
        {text:"Annulation et pertubation voyage",value: "TRIP_CANCELLATION"},
        {text: "Bagages", value: "LUGGAGE"},
        {text: "Covid-19", value: "COVID"}],
      reservation: {
        has_trip_insurance: false,
        has_allergy: false,
        fully_vax: false
      },

    }
  },
  setup(props) {

    let extra_information = ref(props.currentReservation.extra_information);

    return {
      extra_information
    }
  },
  computed:{
    setInsuranceType() {
      const types = {
        MEDICAL: "Médical",
        TRIP_CANCELLATION: "Annulation et pertubation voyage",
        LUGGAGE: "Bagages",
        COVID: "Covid-19",
      }
      return type => types[type];
    },
  },
  methods: {

    submitReservationDetails() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$emit('submitReservationDetails', this.extra_information)
          this.$refs.modalDetails.hide();
        }
      })

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';


.style-chooser .vs__dropdown-toggle,
.style-chooser  .vs__search,
.style-chooser .vs__open-indicator{
  background: #d5d5d5;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}


.medical-radios {
  margin-top:1rem;
}

</style>
