<template>
  <b-card border-variant="primary">
    <div class="d-flex flex-row align-items-center mb-2">
      <b-img v-if="question.image_url" :src="question.image_url" fluid></b-img>
      <div class="d-flex flex-column">
        <div class="d-flex flex-row">
          <h5 class="mb-0">
            {{ question.title
            }}<span v-if="question.is_required" class="text-danger">*</span>
          </h5>
          <span class="ml-50" v-if="isTravelerSpecificQuestion">
            <b-badge variant="badge badge-light-primary">
              Choix distinct par voyageur
            </b-badge>
          </span>
        </div>
        <p class="mb-0 description" v-if="question.description">
          {{ question.description }}
        </p>
      </div>
    </div>

    <b-tabs v-if="isTravelerSpecificQuestion">
      <b-tab
        v-for="(traveler, travelerIndex) in travelers"
        :key="travelerIndex"
      >
        <template #title>
          <span>{{ traveler.name }}</span>
        </template>
        <div class="answer-input-container" v-if="choiceType !== 'text'">
          <AnswerInputContainer
            v-for="choice in availableChoices"
            :key="choice.id"
            :choice="choice"
            :question-id="question.id"
            :traveler-id="traveler.id"
            :answers="question.answers"
            :type="question.choice_type"
            @update="updateAnswer($event, traveler.id)"
          />
        </div>
        <div v-else>
          <AnswerInputContainer
            :type="question.choice_type"
            :answers="question.answers"
            :traveler-id="traveler.id"
            @update="updateAnswer($event, traveler.id)"
          />
        </div>
      </b-tab>
    </b-tabs>

    <div v-else>
      <div
        class="answer-input-container"
        v-if="question.choice_type !== 'text'"
      >
        <AnswerInputContainer
          v-for="choice in availableChoices"
          :key="choice.id"
          :choice="choice"
          :question-id="question.id"
          :answers="question.answers"
          :type="question.choice_type"
          @update="updateAnswer($event)"
        />
      </div>
      <div v-else>
        <AnswerInputContainer
          :type="question.choice_type"
          :answers="question.answers"
          @update="updateAnswer($event)"
        />
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BImg,
  BTabs,
  BTab,
  BRow,
  BCol,
  BCard,
  BAlert,
  BCardText,
  BButton,
  BFormGroup,
  BFormCheckbox,
  BFormRadio,
  BFormRadioGroup,
  BSpinner,
  BBadge,
} from "bootstrap-vue";

import AnswerInputContainer from "@/modules/additional-option-sections/components/AnswerInputContainer.vue";

import { useToastNotification } from "@/shared/composables/use-toast-notifications";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { ref, reactive } from "@vue/composition-api";

export default {
  components: {
    BImg,
    BTabs,
    BTab,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardText,
    BButton,
    BFormRadioGroup,
    BFormGroup,
    BFormCheckbox,
    BFormRadio,
    BAlert,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    AnswerInputContainer,
  },
  props: ["question", "travelers", "reservation_id"],
  setup(props, ctx) {
    const { displaySuccessMessage, displayErrorMessage } =
      useToastNotification();

    const answers = ref([]);
    const questionAnswers = ref([]);
    const currentQuestion = reactive({});
    Object.assign(currentQuestion, props.question);

    let answerInitialState = {
      question_id: null,
      reservation_id: null,
      traveler_id: null,
      choice_id: null,
      text_value: null,
    };

    if (currentQuestion.answers.length > 0) {
      currentQuestion.answers.forEach((answer) => {
        questionAnswers.value.push({
          id: answer.id,
          question_id: currentQuestion.id,
          reservation_id: props.reservation_id,
          traveler_id: answer.traveler_id,
          choice_id: answer.choice_id,
          text_value: answer.text_value,
        });
      });
    }

    return {
      answerInitialState,
      questionAnswers,
      answers,
      displayErrorMessage,
      displaySuccessMessage,
    };
  },
  computed: {
    availableChoices() {
      return this.question.choices;
    },
    choiceType() {
      return this.question.choice_type;
    },
    choices() {
      return this.question.choices;
    },
    isTravelerSpecificQuestion() {
      return this.question.is_traveler_specific;
    },
  },
  methods: {
    getAnswerId(travelerId = null) {
      if (this.question.is_traveler_specific && travelerId) {
        return this.question.answers.find((x) => x.traveler_id === travelerId)
          ?.id;
      }

      return this.question.answers[0]?.id;
    },
    getAnswer(travelerId = null) {
      if (this.question.is_traveler_specific && travelerId) {
        return this.question.answers.find((x) => x.traveler_id === travelerId)
          ?.answer;
      }

      return this.question.answers[0]?.answer;
    },
    updateAnswer(newValue, travelerId = null) {
      const payload = {
        traveler_id: travelerId,
        choice: newValue,
      };
      this.$emit("update", payload);
    },
    updateQuestionAnswers() {
      this.questionAnswers = [];
      this.answers.forEach((x, index) => {
        const newAnswer = Object.assign({}, this.answerInitialState);

        if (this.choiceType === "text") {
          newAnswer.text_value = x;
        } else {
          newAnswer.choice_id = x;
        }

        newAnswer.question_id = this.question.id;
        newAnswer.reservation_id = this.reservation_id;
        this.questionAnswers.push(newAnswer);
      });
    },
    getCurrentAnswer(value, travelerId = null) {
      const answer = Object.assign({}, this.answerInitialState);

      if (this.choiceType === "text") {
        answer.text_value = value;
      } else {
        answer.choice_id = value;
      }

      if (travelerId) {
        answer.traveler_id = travelerId;
      }
    },
    addAnswer(newValue, travelerId = null) {
      const currentAnswer = this.getCurrentAnswer(newValue, travelerId);
      let answerIndex = 0;

      if (!this.question.is_traveler_specific) {
        if (this.choiceType === "multiple") {
          answerIndex = this.answers.findIndex((x) => x.choice_id === newValue);
        } else {
          answerIndex = 0;
        }
      } else {
        if (this.choiceType === "multiple") {
          answerIndex = this.answers.findIndex(
            (x) => x.choice_id === newValue && x.traveler_id === travelerId
          );
        } else {
          answerIndex = this.answers.findIndex(
            (x) => x.choice_id === newValue && x.traveler_id === travelerId
          );
        }
      }

      if (this.choiceType === "multiple") {
        if (this.answers.includes(newValue)) {
          let index = this.answers.indexOf(newValue);
          this.answers.splice(index, 1);
        } else {
          this.answers.push(newValue);
        }
      } else {
        this.answers = [newValue];
      }

      this.updateQuestionAnswers();
    },
    updateValue(newValue, travelerId = null) {
      let index = travelerId
        ? this.answers.indexOf(
            this.answers.find((x) => x.traveler_id === travelerId)
          )
        : 0;

      if (this.choiceType === "multiple") {
        if (this.answers[index].includes(newValue)) {
          let newValueIndex = this.answers[index].indexOf(newValue);
          this.answers[index].splice(newValueIndex, 1);
        } else {
          this.answers[index] = [...this.answers[index], newValue];
        }
      } else {
        this.answers[index] = [newValue];
      }
    },
    submitAnswer() {},
    submit(payload) {
      const answerId = this.getAnswerId(payload.traveler_id);
      if (answerId) {
        payload.answer_id = answerId;
        this.$emit("update", payload);
      } else {
        this.$emit("create", payload);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.gap-1 {
  gap: 1.5rem;
}

h6 label {
  font-size: 1rem !important;
}

.answer-input-container:has(.answer-input-container--multiple) {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  gap: 1rem;
}

.answer-input-container:has(.answer-input-container--single) {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  gap: 1rem;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Nombre de lignes max */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

img {
  margin-right: 1rem;
  height: 100px !important;
  object-fit: cover;
  border-radius: 0.358rem;
}
</style>
