<template>
  <div>
    <div v-if="type !== 'text'" :class="`answer-input-container--${type}`">
      <b-form-checkbox
        v-if="type === 'multiple'"
        :id="inputId"
        v-model="inputValue"
        :disabled="isDisabled"
        :value="choice.id"
        @change="$emit('update', choice.id)"
      />
      <b-form-radio
        v-else
        :id="inputId"
        :name="radioInputName"
        :disabled="isDisabled"
        v-model="inputValue"
        :value="choice.id"
        @change="$emit('update', choice.id)"
      />
      <div class="d-flex flex-column ml-50">
        <div class="d-flex flex-row">
          <h6 class="mb-0">
            <label :for="inputId">{{ choice.title }}</label>
          </h6>
          <h6 class="mb-0 ml-25" v-if="choice.price">- {{ getPrice() }}</h6>
        </div>
        <small v-if="choice.description">
          {{ choice.description }}
        </small>
      </div>
    </div>
    <div v-else class="d-flex flex-row">
      <b-form-textarea
        placeholder=""
        rows="2"
        :value="inputValue"
        maxlength="2000"
        @input="(input) => $emit('update', input)"
      />
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BAlert,
  BButton,
  BFormGroup,
  BFormCheckbox,
  BFormRadio,
  BFormRadioGroup,
  BFormTextarea,
} from "bootstrap-vue";

import { useToastNotification } from "@/shared/composables/use-toast-notifications";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  components: {
    BFormTextarea,
    BRow,
    BCol,
    BButton,
    BFormRadioGroup,
    BFormGroup,
    BFormCheckbox,
    BFormRadio,
    BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  props: ["choice", "answers", "type", "travelerId", "questionId"],
  setup(props, ctx) {
    const { displaySuccessMessage, displayErrorMessage } =
      useToastNotification();

    return {
      displayErrorMessage,
      displaySuccessMessage,
    };
  },
  computed: {
    inputId() {
      return this.radioInputName + "-" + this.choice.id;
    },
    isDisabled() {
      return this.choice.has_inventory && this.choice.remaining_inventory === 0;
    },
    radioInputName() {
      if (this.travelerId) {
        return `question-id-${this.questionId}-${this.travelerId}`;
      }
      return `question-id-${this.questionId}`;
    },
    inputValue: {
      get() {
        if (this.type !== "text") {
          let answerIds = [];

          if (this.travelerId) {
            answerIds = this.answers
              .filter((x) => x.traveler_id === this.travelerId)
              .map((x) => x.choice_id);
          } else {
            answerIds = this.answers.map((x) => x.choice_id);
          }

          if (answerIds.includes(this.choice.id)) {
            return this.choice.id;
          }
        }

        if (this.travelerId) {
          return this.answers.find((x) => x.traveler_id === this.travelerId)
            ?.text_value;
        }

        return this.answers[0]?.text_value;
      },
      set() {},
    },
  },
  methods: {
    getPrice() {
      let value = 0.0;
      if (this.choice.price) {
        value = this.choice.price / 100;
      }
      return value.toFixed(2) + " $";
    },
  },
};
</script>
<style lang="scss" scoped>
.answer-input-container--multiple,
.answer-input-container--single {
  display: flex;
  flex-direction: row;
}
</style>
