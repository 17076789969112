<template>
  <validation-observer ref="simpleRules">
    <b-form>
      <b-row class="mb-2">
        <b-col md="8">
          <h1 class="section-label mx-0 mt-1">
            Informations personnelles
          </h1>
        </b-col>
        <b-col md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="primary"
            class="float-right"
            v-if="canCopy"
            @click.prevent="copyFromFirstTraveler"
          >
            <feather-icon icon="UsersIcon" size="15" />
            Copier données du 1er voyageur
          </b-button>
        </b-col>
        <b-col v-if="isGroupX" md="12">
          <p>
            <small><i>Les renseignements personnels recueillis sont exigés par les fournisseurs de services pour des fins de créations de compte clients.</i></small>
          </p>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="3">
          <b-form-group label="Type" label-for="Type">
            <v-select
              v-if="!isChildTraveler"
              class="style-chooser"
              :clearable="false"
              :disabled="true"
              :searchable="false"
              value="Adulte"
            />
            <validation-provider
              v-else
              #default="{ errors }"
              name="Type de voyageur"
              rules="required"
            >
              <v-select
                v-model="traveler.type"
                :options="getAgeCategories()"
                :clearable="false"
                label="text"
                :reduce="(option) => option.value"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <div style="margin-top: 25px">
            <b-form-checkbox
              v-model="traveler.reservation_owner"
              :value="true"
              :unchecked-value="false"
              :disabled="hasPrimary && !traveler.reservation_owner && isChildTraveler"
              class="custom-control-primary"
            >
              Contact principal
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" v-if="isPublic">
          <b-alert show>
            <p class="p-1">
              <feather-icon icon="InfoIcon" size="26" />
              Votre prénom et nom doivent être identique à votre passport
            </p>
          </b-alert>
        </b-col>

        <b-col md="3">
          <validation-provider
            #default="{ errors }"
            name="Prénom voyageur"
            rules="required"
          >
            <b-form-group label="Prénom" label-for="Prénom">
              <b-form-input
                v-model="traveler.first_name"
                :class="{ 'is-invalid': errors.length > 0 }"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="3">
          <b-form-group label="2ème prénom" label-for="2ème prénom">
            <b-input-group>
              <b-input-group-prepend is-text>
                <b-form-checkbox
                  name="checkbox-input"
                  v-model="hasSecondName"
                />
              </b-input-group-prepend>
              <b-form-input
                :disabled="!hasSecondName"
                v-model="traveler.middle_name"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <validation-provider
            #default="{ errors }"
            name="Nom voyageur"
            rules="required"
          >
            <b-form-group label="Nom" label-for="Nom">
              <b-form-input
                v-model="traveler.last_name"
                :class="{ 'is-invalid': errors.length > 0 }"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group label="Date de naissance" label-for="Date de naissance">
            <b-form-input
              id="departure-date"
              class="mb-1"
              type="date"
              max="2100-01-01"
              v-model="birthdayDate"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <validation-provider
            #default="{ errors }"
            name="Genre"
            rules="required"
          >
            <b-form-group>
              <label for="Genre"
                >Genre
                <feather-icon
                  icon="HelpCircleIcon"
                  size="21"
                  class="text-muted cursor-pointer"
                  v-b-tooltip.hover.top.html="
                    'F - Féminin<br>M – Masculin<br>X – Autre genre'
                  "
                />
              </label>

              <v-select
                id="group-type"
                :options="genderType"
                v-model="traveler.gender"
                :selectable="(option) => !option.value.includes('select_value')"
                label="text"
                :reduce="(text) => text.value"
                :class="{ 'v-select-is-invalid': errors.length > 0 }"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="3">
          <validation-provider
            #default="{ errors }"
            name="Numéro de télèphone"
            rules="required"
          >
            <b-form-group
              label="Numéro de téléphone cellulaire"
              label-for="Numéro de téléphone cellulaire"
            >
              <b-form-input
                v-model="traveler.phone"
                v-mask="'(###) ###-####'"
                :class="{ 'is-invalid': errors.length > 0 }"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="3">
          <validation-provider
            #default="{ errors }"
            name="Couriel"
            rules="required|email"
          >
            <b-form-group label="Courriel" label-for="Courriel">
              <b-form-input
                v-model="traveler.email"
                :class="{ 'is-invalid': errors.length > 0 }"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <h1 class="section-label mx-0 mb-2 mt-1">Adresse</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <validation-provider #default="{ errors }" name="Adresse">
            <b-form-group
              label="Adresse"
              label-for="Adresse"
              :description="mandatory_description"
            >
              <b-form-input
                v-model="traveler.address"
                :class="{ 'is-invalid': errors.length > 0 }"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="3">
          <validation-provider
            #default="{ errors }"
            name="Code postal"
            rules="required"
          >
            <b-form-group label="Code Postal" label-for="Code Postal">
              <b-form-input
                v-model="traveler.postal_code"
                v-mask="'A#A #A#'"
                :class="{ 'is-invalid': errors.length > 0 }"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <validation-provider #default="{ errors }" name="Ville">
            <b-form-group
              label="Ville"
              label-for="Ville"
              :description="mandatory_description"
            >
              <b-form-input
                v-model="traveler.city"
                :class="{ 'is-invalid': errors.length > 0 }"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="3">
          <validation-provider
            #default="{ errors }"
            name="Province"
            rules="required"
          >
            <b-form-group label="Province" label-for="Province">
              <b-form-input
                v-model="traveler.province"
                :class="{ 'is-invalid': errors.length > 0 }"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="3">
          <validation-provider
            #default="{ errors }"
            name="Pays"
            rules="required"
          >
            <b-form-group label="Pays" label-for="Pays">
              <b-form-input
                v-model="traveler.country"
                :class="{ 'is-invalid': errors.length > 0 }"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <h1 class="section-label mx-0 mb-2 mt-1">Nom du contact d'urgence</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Nom contact d'urgence"
          >
            <b-form-group
              label="Nom du contact d'urgence"
              label-for="Nom du contact d'urgence"
              :description="mandatory_description"
            >
              <b-form-input
                v-model="traveler.emergency_contact_name"
                :class="{ 'is-invalid': errors.length > 0 }"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="3">
          <validation-provider #default="{ errors }" name="Téléphone">
            <b-form-group
              label="Téléphone"
              label-for="Téléphone"
              :description="mandatory_description"
            >
              <b-form-input
                v-model="traveler.emergency_contact_phone"
                v-mask="'(###) ###-####'"
                :class="{ 'is-invalid': errors.length > 0 }"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" class="mb-2 mt-1">
          <h1 class="section-label mx-0">Information médicale</h1>
          <small
            ><i
              >Si votre état de santé nécessite des besoins spéciaux, si vous
              avez des allergies ou un régime alimentaire particulier, ceux-ci
              doivent être mentionnés au moment de la réservation.</i
            ></small
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="besoins particuliers"
            rules="required"
          >
            <b-form-group
              label="Condition de santé préexistante qui nécessite des besoins particuliers?"
              label-for="Condition de santé préexistante qui nécessite des besoins particuliers?"
            >
              <b-form-radio-group
                v-model="traveler.prior_medical_condition"
                :options="yesNoOptions"
                class="medical-radios"
                :class="{ 'is-invalid': errors.length > 0 }"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="6" v-if="traveler.prior_medical_condition">
          <validation-provider
            #default="{ errors }"
            name="Détails de la condition"
            :rules="{ required: traveler.prior_medical_condition }"
          >
            <b-form-group
              label="Détails de la condition"
              label-for="Détails de la condition"
            >
              <b-form-textarea
                rows="4"
                size="sm"
                placeholder="Détails sur la condition préexistante"
                v-model="traveler.preexisting_condition_details"
              >
              </b-form-textarea>
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <!--
      <b-col md="4" v-if="traveler.prior_medical_condition">
        <b-form-group
          label="Votre problème de santé est stable et contrôlé depuis?"
          label-for="Votre problème de santé est stable et contrôlé depuis?"
        >
          <v-select
            id="group-type"
            :options="['Non contrôlé','Moins de 3 mois','3 à 6 mois', '6 mois et plus']"
            v-model="traveler.medical_condition_stability"
          />
        </b-form-group>
      </b-col>
      -->
      </b-row>
      <hr class="my-2" />
      <b-row class="mt-2">
        <b-col md="3">
          <validation-provider
            #default="{ errors }"
            name="Allergies"
            rules="required"
          >
            <b-form-group
              label="Avez-vous des allergies?"
              label-for="Avez-vous des allergies?"
            >
              <b-form-radio-group
                v-model="traveler.has_allergies"
                :options="yesNoOptions"
                class="medical-radios"
                :class="{ 'is-invalid': errors.length > 0 }"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="3" v-if="traveler.has_allergies">
          <validation-provider
            #default="{ errors }"
            name="Allergies"
            rules="required"
          >
            <b-form-group
              label="Veuillez indiquer les allergies"
              label-for="Veuillez indiquer les allergies"
            >
              <b-form-input
                v-model="traveler.allergies"
                :class="{ 'is-invalid': errors.length > 0 }"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="3" v-if="traveler.has_allergies">
          <validation-provider
            #default="{ errors }"
            name="EpiPen"
            rules="required"
          >
            <b-form-group
              label="Voyagez-vous avec votre EpiPen?"
              label-for="Voyagez-vous avec votre EpiPen?"
            >
              <b-form-radio-group
                :options="yesNoOptions"
                v-model="traveler.requires_epipen_on_self"
                class="medical-radios"
                :value="true"
                :unchecked-value="false"
                :class="{ 'is-invalid': errors.length > 0 }"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <hr class="my-2" />
      <b-row class="mt-2">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="numéro de membre"
            rules="required"
          >
            <b-form-group
              label="Possédez-vous un numéro de membre ou carte fidélité avec la compagnie aérienne, l’hôtel ou la compagnie de croisière?"
              label-for="Possédez-vous un numéro de membre ou carte fidélité avec la compagnie aérienne, l’hôtel ou la compagnie de croisière?"
            >
              <b-form-radio-group
                v-model="traveler.has_membership"
                :options="yesNoOptions"
                class="medical-radios"
                :class="{ 'is-invalid': errors.length > 0 }"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="6" v-if="traveler.has_membership">
          <b-row>
            <b-col md="12">
              <validation-provider
                #default="{ errors }"
                name="numéro de membre"
                :rules="{ required: traveler.has_membership }"
              >
                <b-form-group
                  label="Numéro de membre"
                  label-for="Numéro de membre"
                >
                  <b-form-input
                    v-model="traveler.membership_number"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <validation-provider
                #default="{ errors }"
                name="Nom de la compagnie"
                :rules="{ required: traveler.has_membership }"
              >
                <b-form-group
                  label="Nom de la compagnie associée au numéro"
                  label-for="Nom de la compagnie associée au numéro"
                >
                  <b-form-input
                    v-model="traveler.membership_company"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!--
    <b-row class="mt-2">
      <b-col md="6">
        <b-form-group
          label="Êtes-vous entiérement vaccinés contre la Covid-19?"
          label-for="Êtes-vous entiérement vaccinés contre la Covid-19?"
        >
          <b-form-radio-group
            v-model="traveler.fully_vax"
            :options="yesNoOptions"
            class="medical-radios"

          />
        </b-form-group>
      </b-col>
      <b-col md="6" v-if="!traveler.fully_vax">
        <b-form-group
          label="Avez-vous l’intention d'être vacciné deux doses avant votre départ en voyage?"
          label-for="Avez-vous l’intention d'être vacciné deux doses avant votre départ en voyage?"
        >
          <b-form-radio-group
            :options="yesNoOptions"
            class="medical-radios"
            v-model="traveler.fully_vaccinated_before_trip"
          />
        </b-form-group>
      </b-col>

    </b-row>
    -->
    </b-form>
  </validation-observer>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { localize, ValidationProvider, ValidationObserver } from "vee-validate";
import fr from "vee-validate/dist/locale/fr.json";
import { ref, computed } from "@vue/composition-api";
import Tenant from "@/plugins/tenant";
import groupCreationConfig from '@core/data/groupCreationConfig.js'

import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BCardText,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  VBTooltip,
  BAlert,
  BFormCheckbox,
  BInputGroupPrepend,
  BFormRadio,
  BFormTextarea,
  BTabs,
  BFormFile,
  BFormCheckboxGroup,
  BTab,
  BFormInvalidFeedback,
  BFormRadioGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import dayjs from "dayjs";

localize("fr", fr);

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    BAlert,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BCardText,
    BFormGroup,
    vSelect,
    BRow,
    BFormRadioGroup,
    BCol,
    BInputGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BTabs,
    BFormFile,
    BTab,
    BFormInvalidFeedback,
    BFormRadio,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
    "b-tooltip": VBTooltip,
  },
  name: "FormTraveler",
  props: [
    "currentTraveler",
    "hasPrimary",
    "currentRoom",
    "isChildTraveler",
    "isPublic",
  ],
  data() {
    return {
      canCopy: false,
      genderType: [
        { text: "F", value: "WOMAN" },
        { text: "M", value: "MAN" },
        { text: "X", value: "TRANS" },
      ],
      traveler:
        this.currentTraveler != null
          ? this.currentTraveler
          : { reservation_owner: !this.hasPrimary && !this.isChildTraveler },
      yesNoOptions: [
        { text: "oui", value: true },
        { text: "non", value: false },
      ],
      hasSecondName:
        this.traveler != null ? this.traveler.middle_name != null : false,
    };
  },
  setup(props) {
    let mandatory_description = ref("");
    if (!props.isPublic) {
      mandatory_description.value = "Facultatif";
    }

    const isGroupX = computed(() => {
      const tenantId = Tenant.resolveTenantId();
      return tenantId === "transat";
    });

    return {
      mandatory_description,
      groupCreationConfig,
      isGroupX
    };
  },
  created() {
    this.checkIfCanCopyTraveler();
  },
  methods: {
    getAgeCategories() {
      return groupCreationConfig.ageCategories.filter((x) => {
          return this.currentRoom.occupation_type.supported_children_types?.includes(
            x.value
          );
      });
    },
    checkIfCanCopyTraveler() {
      if (
        this.typeToNumberTravelers(this.currentRoom.occupation_type.type) > 1
      ) {
        this.canCopy = this.firstTraveler !== undefined && this.firstTraveler !== this.currentTraveler
      }
    },
    copyFromFirstTraveler() {
      this.traveler = {
        email: this.firstTraveler.email,
        phone: this.firstTraveler.phone,
        address: this.firstTraveler.address,
        postal_code: this.firstTraveler.postal_code,
        city: this.firstTraveler.city,
        province: this.firstTraveler.province,
        country: this.firstTraveler.country,
        emergency_contact_name:
          this.firstTraveler.emergency_contact_name,
        emergency_contact_phone:
          this.firstTraveler.emergency_contact_phone,
      };
    },
  },
  computed: {
    firstTraveler() {
      return this.currentRoom.travelers.find(x => x.type === 'ADULT')
    },
    typeToNumberTravelers() {
      const types = {
        SINGLE: 1,
        DOUBLE: 2,
        TRIPLE: 3,
        QUADRUPLE: 4,
      };
      return (type) => types[type];
    },
    birthday: {
      get() {
        if (this.traveler.birthday && Array.isArray(this.traveler.birthday)) {
          return this.traveler.birthday.join("-");
        }
        return this.traveler.birthday;
      },
      set(birthday) {
        this.traveler.birthday = birthday;
      },
    },
    birthdayDate: {
      get() {
        let birthday = this.traveler.birthday;
        if (Array.isArray(birthday)) {
          birthday = dayjs(birthday.join("-")).format("YYYY-MM-DD");
        }
        return birthday;
      },
      set(value) {
        this.traveler.birthday = value;
      },
    },
  },
  watch: {
    "traveler.has_allergies"(newValue, oldValue) {
      if (newValue === false || this.traveler.allergies === "NO_PREFERENCES") {
        this.traveler.allergies = undefined;
      }
    },
  },
};
</script>
