<template>
  <b-row class="match-height">
    <b-col lg="12" md="12">
      <div v-if="currentGroupId" class="mt-1">
        <div class="mt-0">
          <b-form>
            <b-card header-tag="header" class="mx-auto" id="printMe">
              <b-row>
                <b-col>
                  <h3 class="pb-2">Options supplémentaires</h3>
                </b-col>
              </b-row>

              <b-row
                v-if="
                  showAdditionalOptions &&
                  reservationOptions.length > 0 &&
                  emptyTravelers
                "
                class="mb-1"
              >
                <b-col>
                  <b-alert variant="danger" show class="mb-0">
                    <div class="alert-body">
                      <feather-icon icon="InfoIcon" class="mr-50" />
                      <b>Attention</b> - Aucun voyageur n'est associé à votre
                      réservation. Veuillez assigner vos voyageurs avant
                      d'ajouter des options supplémentaires.
                    </div>
                  </b-alert>
                </b-col>
              </b-row>

              <div
                v-if="!reservationOptions || reservationOptions.length == 0"
                class="my-1"
              >
                Aucune option supplémentaire n'est disponible pour cette
                réservation.
              </div>

              <div v-else>
                <div v-if="hasErrors">
                  <b-alert show variant="danger">
                    <div
                      v-for="(errors, index) in validationErrors"
                      :key="index"
                      class="p-1"
                    >
                      <p v-for="error in errors" :key="error">
                        {{ error }}
                      </p>
                    </div>
                  </b-alert>
                </div>
                <div
                  v-for="(section, sectionIndex) in reservationOptions"
                  :key="sectionIndex"
                >
                  <b-card
                    border-variant="secondary"
                    :header="section.title"
                    header-bg-variant="primary"
                    header-text-variant="white"
                  >
                    <b-row>
                      <b-col cols="12" class="mt-2">
                        <div
                          v-for="question in section.questions"
                          :key="question.id"
                        >
                          <QuestionAnswerSection
                            :question="question"
                            :travelers="currentTravelers"
                            :reservation_id="currentReservation.id"
                            @update="updateAnswer(question, $event)"
                          />
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </div>
              </div>

              <template #footer class="card-footer">
                <b-button variant="primary" @click="nextTab()">
                  Suivant
                </b-button>
              </template>
            </b-card>
          </b-form>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BCard,
  BAlert,
  BCardText,
  BButton,
  BFormGroup,
  BFormCheckbox,
  BFormRadio,
  BFormRadioGroup,
  BForm,
  BSpinner,
  BBadge,
} from "bootstrap-vue";
import { ref, computed } from "@vue/composition-api";

import { useApplicationContext } from "@/shared/composables/use-application-context";

import { GroupAdditionalOptionService } from "@/views/groups/services/GroupAdditionalOptionService.js";

import QuestionAnswerSection from "@/modules/additional-option-sections/components/QuestionAnswerSection.vue";

import { onMounted } from "@vue/composition-api";

import { useToastNotification } from "@/shared/composables/use-toast-notifications";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import _ from "lodash";

export const additionalOptionService = new GroupAdditionalOptionService();

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardText,
    BButton,
    BFormRadioGroup,
    BFormGroup,
    BFormCheckbox,
    BFormRadio,
    BAlert,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    QuestionAnswerSection,
    BForm,
  },
  props: [
    "currentGroup",
    "currentReservation",
    "errors",
    "currentGroupId",
    "currentTravelers",
    "options",
  ],
  data() {
    return {
      hasErrors: false,
      validationErrors: [],
      reservationOptions: [],
    };
  },
  setup(props, ctx) {
    const { displaySuccessMessage, displayErrorMessage } =
      useToastNotification();
    const { forceUpdate } = useApplicationContext();
    const publicAccessToken = ctx.root.$route.query.token;

    return {
      publicAccessToken,
      displayErrorMessage,
      displaySuccessMessage,
    };
  },
  watch: {
    options(newValue) {
      this.reservationOptions = newValue;
    },
  },
  computed: {
    showAdditionalOptions() {
      return this.$store.state["app-auth"]?.orgSettings
        ?.additional_options_enable;
    },
    emptyTravelers() {
      return !this.currentTravelers || this.currentTravelers.length === 0;
    },
  },
  methods: {
    nextTab() {
      if (!this.reservationOptions && this.reservationOptions.length === 0) {
        this.$emit("nextTab");
      } else {
        this.submitAnswers();
      }
    },
    updateAnswer(question, payload) {
      const newAnswer = {
        text_value: question.choice_type === "text" ? payload.choice : null,
        choice_id: question.choice_type !== "text" ? payload.choice : null,
        traveler_id: payload.traveler_id,
      };

      if (["single", "text"].includes(question.choice_type)) {
        if (question.is_traveler_specific) {
          const foundAnswer = question.answers.find(
            (x) => x.traveler_id === newAnswer.traveler_id
          );
          if (foundAnswer) {
            const index = question.answers.indexOf(foundAnswer);
            question.answers.splice(index, 1, newAnswer);
          } else {
            question.answers.push(newAnswer);
          }
        } else {
          question.answers.splice(0, 1, newAnswer);
        }
      } else {
        let foundAnswer = "";

        if (question.is_traveler_specific) {
          foundAnswer = question.answers.find(
            (x) =>
              x.choice_id === newAnswer.choice_id &&
              x.traveler_id === newAnswer.traveler_id
          );
        } else {
          foundAnswer = question.answers.find(
            (x) => x.choice_id === newAnswer.choice_id
          );
        }

        if (foundAnswer) {
          const index = question.answers.indexOf(foundAnswer);
          question.answers.splice(index, 1);
        } else {
          question.answers.push(newAnswer);
        }
      }
    },
    getAnswersPayload() {
      const options = Object.assign({}, this.reservationOptions);
      const payload = [];

      Object.entries(options).forEach(([key, value]) => {
        const questions = value.questions;
        questions.forEach((question) => {
          if (question.answers.length > 0) {
            question.answers.forEach((answer) => {
              payload.push({
                question_id: question.id,
                traveler_id: answer.traveler_id,
                choice_id: answer.choice_id,
                text_value: answer.text_value,
              });
            });
          }
        });
      });

      return payload;
    },
    fetchReservationOptions(groupId, reservationId) {
      if (groupId && reservationId) {
        additionalOptionService
          .getReservationOptions(
            this.$route.params.id,
            this.$route.params.reservation_id,
            this.publicAccessToken
          )
          .then((response) => {
            this.reservationOptions = response.data;
          })
          .catch((e) => {
            console.error(e);
            this.displayErrorMessage(
              "Une erreur est arrivée en essayant de retrouver les informations d'options supplementaires de la réservation."
            );
          });
      }
    },
    submitAnswers() {
      this.hasErrors = false;
      const payload = this.getAnswersPayload();

      additionalOptionService
        .updateAdditionalOptionAnswers(
          this.$route.params.id,
          this.$route.params.reservation_id,
          payload,
          this.publicAccessToken
        )
        .then((response) => {
          this.fetchReservationOptions(
            this.$route.params.id,
            this.$route.params.reservation_id
          );
          // this.$forceUpdate();
          this.displaySuccessMessage(
            "Les options supplementaires ont bien été modifiées"
          );
          // this.$nextTick(() => {
          //   this.tabIndex = 3;
          // });
          this.$emit("nextTab");
        })
        .catch((e) => {
          console.error(e);
          this.hasErrors = true;
          this.validationErrors = e?.data?.errors;
          this.displayErrorMessage(e?.data?.message);
        });
    },
  },
};
</script>
