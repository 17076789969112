<template>
  <div class="mt-0">
    <b-card header-tag="header" class="mx-auto" id="printMe">
      <b-row>
        <b-col>
          <h3>Sommaire et confirmation</h3>
        </b-col>

        <b-col v-if="currentReservation.status == 'SUBMITTED'" class="no-print">
          <b-button class="float-right ml-1" variant="secondary" @click="print">
            <feather-icon icon="PrinterIcon" size="16" />
            <span class="d-none d-lg-inline-block ml-md-1">Imprimer</span>
          </b-button>
          <b-button
            class="float-right"
            variant="info"
            @click="showReservationSummaryEmailModal"
          >
            <feather-icon icon="SendIcon" size="16" />
            <span class="d-none d-lg-inline-block ml-md-1"
              >Envoyer par courriel</span
            >
          </b-button>

          <ReservationSummaryEmailModal
            v-if="currentReservation"
            ref="res-summary-email-modal"
            :reservation="currentReservation"
            @submit="sendSummaryEmail($event)"
          />
        </b-col>
      </b-row>
      <b-row
        v-if="
          currentReservation.status !== 'SUBMITTED' &&
          hasPrimaryContact &&
          !hasExceededInventory
        "
        class="mb-1"
      >
        <b-col>
          <b-alert variant="warning" show class="mb-0">
            <div class="alert-body">
              <feather-icon icon="InfoIcon" class="mr-50" />
              <b>Attention</b> - Votre réservation n'est pas confirmée -
              Veuillez dérouler la page et
              <span v-if="paymentEnabled">
                confirmer en inscrivant votre paiement.
              </span>
              <span v-else> cliquer sur le bouton Confirmer. </span>
            </div>
          </b-alert>
        </b-col>
      </b-row>

      <b-row v-if="!hasPrimaryContact" class="mb-1">
        <b-col>
          <b-alert variant="danger" show class="mb-0">
            <div class="alert-body">
              <feather-icon icon="InfoIcon" class="mr-50" />
              <b>Attention</b> - Il n'y a pas de contact principal associé à
              votre réservation. Veuillez choisir le contact principal à l'étape
              2.
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row v-if="hasExceededInventory" class="mb-1">
        <b-col>
          <b-alert variant="danger" show class="mb-0">
            <div class="alert-body">
              <feather-icon icon="InfoIcon" class="mr-50" />
              <b>Attention</b> - L'inventaire d'un ou plusieurs forfaits
              sélectionnés dans votre réservation est insuffisant pour compléter
              la demande. Veuillez vérifier vos choix de chambres à l'étape 1.
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <template v-if="currentReservation.status === 'SUBMITTED'">
        <b-card
          header-tag="header"
          class="border border-success mt-3"
          border-variant="success"
          header-bg-variant="success"
          header-text-variant="white"
          header-class="mb-1"
        >
          <template #header>
            <h4 class="text-white my-auto">
              <feather-icon icon="CheckCircleIcon" size="32" class="mr-50" />
              Cette réservation a été soumise le
              {{ formatDateFromArray(currentReservation.submitted_at) }}
            </h4>
          </template>
          <b-row class="mt-2">
            <b-col cols="12" md="6" lg="3">
              <div>
                <h5 class="mb-75">Agence</h5>
                <b-card-text>
                  <span v-if="fetchAgencyName(currentReservation)">
                    {{ fetchAgencyName(currentReservation) }}
                  </span>
                  <br />
                  <span v-if="fetchAgencyPhone(currentReservation)">
                    {{ fetchAgencyPhone(currentReservation) }}
                  </span>
                </b-card-text>
              </div>
            </b-col>
            <b-col cols="12" md="6" lg="3">
              <div>
                <h5 class="mb-75">Agent</h5>
                <b-card-text>
                  {{ currentAgentNameAndAgency }}<br />
                  <template v-if="currentAgentPhoneNumber">
                    {{ currentAgentPhoneNumber }}<br />
                  </template>
                  <a :href="`mailto:${currentAgentEmail}`">{{
                    currentAgentEmail
                  }}</a>
                </b-card-text>
              </div>
            </b-col>
            <b-col cols="12" md="6" lg="3">
              <div>
                <h5 class="mb-75">Numéro de réservation</h5>
                <b-card-text>
                  <span class="h2"
                    >#{{ currentReservation.reservation_number }}</span
                  >
                </b-card-text>
              </div>
            </b-col>
            <b-col cols="12" md="6" lg="3">
              <div>
                <h5 class="mb-75">Réservation(s) Tour-Opérateur</h5>
                <b-card-text>
                  <ul
                    v-if="
                      currentReservation.booking_numbers &&
                      currentReservation.booking_numbers.length > 0
                    "
                    class="pl-1"
                  >
                    <li
                      v-for="bookingNumber in currentReservation.booking_numbers"
                      :key="bookingNumber.id"
                    >
                      <strong>{{ bookingNumber.operator }}</strong
                      >: {{ bookingNumber.value }}
                    </li>
                  </ul>
                  <span v-else> À venir </span>
                </b-card-text>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </template>

      <b-row class="mt-2">
        <b-col>
          <h4><span>Informations de base</span></h4>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12" md="6" lg="3">
          <div>
            <h5 class="mb-75">Groupe sélectionné</h5>
            <b-card-text>
              {{ currentReservation.group_name }}
            </b-card-text>
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="3">
          <div>
            <h5 class="mb-75">Forfait sélectionné</h5>
            <b-card-text>
              {{ getPackage(currentReservation) }}
            </b-card-text>
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="3">
          <div>
            <h5 class="mb-75">Nombre de chambre(s)</h5>
            <b-card-text>
              {{ getNbRooms(currentReservation) }}
            </b-card-text>
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="3">
          <div>
            <h5 class="mb-75">Date - création de la réservation</h5>
            <b-card-text>
              {{ formatDateFromArray(currentReservation.created_at) }}
            </b-card-text>
          </div>
        </b-col>
      </b-row>

      <div
        v-for="(
          package_reservation, index
        ) in currentReservation.package_reservations"
        :key="index"
      >
        <b-card
          border-variant="secondary"
          :header="`Chambre ${index + 1} - Occupation ${
            package_reservation.occupation_type.type
          }`"
          header-bg-variant="primary"
          header-text-variant="white"
          class="mt-2"
        >
          <b-row>
            <b-col class="mt-2" cols="12">
              <b-row>
                <b-col cols="12" md="6" lg="3" v-if="isTransat">
                  <div>
                    <h5 class="mb-75">Type de lit</h5>
                    <b-card-text>
                      {{
                        package_reservation.bed_option == "NO_PREFERENCE"
                          ? t("reservations.no_preference")
                          : t(`reservations.${package_reservation.bed_option}`)
                      }}
                    </b-card-text>
                  </div>
                </b-col>
                <b-col cols="12" md="6" lg="3" v-if="!isTransat">
                  <div>
                    <h5 class="mb-75">
                      {{ t("reservations.floor_preference") }}
                    </h5>
                    <b-card-text>
                      {{
                        package_reservation.floor_preference == "NO_PREFERENCE"
                          ? t("reservations.no_preference")
                          : package_reservation.floor_preference
                      }}
                    </b-card-text>
                  </div>
                </b-col>
                <b-col cols="12" md="6" lg="3">
                  <div>
                    <h5 class="mb-75">Options supplémentaires</h5>
                    <b-card-text v-if="hasOptions(package_reservation)">
                      <ul style="list-style: none; padding-inline-start: 2px">
                        <li v-if="package_reservation.extra_bed">
                          <span
                            class="bullet bullet-sm mr-1 bullet-info"
                          ></span>
                          <small>Lit d’appoint supplémentaire</small>
                        </li>
                        <li v-if="package_reservation.playpen">
                          <span
                            class="bullet bullet-sm mr-1 bullet-info"
                          ></span>
                          <small>Parc pour bébé</small>
                        </li>
                        <li v-if="package_reservation.birthday_celebration">
                          <span
                            class="bullet bullet-sm mr-1 bullet-info"
                          ></span>
                          <small>Anniversaire à célébrer</small>
                        </li>
                        <li v-if="package_reservation.honeymoon">
                          <span
                            class="bullet bullet-sm mr-1 bullet-info"
                          ></span>
                          <small>Lune de miel</small>
                        </li>
                        <li v-if="package_reservation.reduced_mobility">
                          <span
                            class="bullet bullet-sm mr-1 bullet-info"
                          ></span>
                          <small>Mobilité réduite</small>
                        </li>
                        <li
                          v-if="
                            package_reservation.requires_wheelchair_assistance
                          "
                        >
                          <span
                            class="bullet bullet-sm mr-1 bullet-info"
                          ></span>
                          <small
                            >Assistance fauteuil roulant aux aéroports</small
                          >
                        </li>
                      </ul>
                    </b-card-text>
                    <b-card-text v-else>
                      <i> Aucune option </i>
                    </b-card-text>
                  </div>
                </b-col>
              </b-row>
              <b-card
                border-variant="primary"
                class="mt-2 mb-1"
                v-for="traveler in package_reservation.travelers"
                :key="traveler.id"
              >
                <b-row>
                  <b-col class="mb-1" cols="12">
                    <h4>
                      {{ traveler.first_name }}
                      <span v-if="traveler.middle_name">{{
                        traveler.middle_name
                      }}</span>
                      {{ traveler.last_name }} -
                      <i class="text-muted">{{
                        t(`reservations['${traveler.type}']`)
                      }}</i>
                    </h4>
                  </b-col>
                  <b-col cols="12" md="6" lg="3">
                    <div>
                      <h5 class="mb-75">
                        <feather-icon icon="ClipboardIcon" size="16" />
                        Informations personnelles
                      </h5>
                      <b-card-text>
                        <feather-icon icon="UserIcon" size="12" class="blue" />
                        <span class="ml-1">{{
                          t(`reservations['${traveler.gender}']`)
                        }}</span>
                        <br />
                        <feather-icon icon="CalendarIcon" size="12" />
                        <span class="ml-1">{{
                          getBirthday(traveler.birthday)
                        }}</span>
                        <br />
                        <div v-if="traveler.has_membership">
                          <feather-icon icon="GlobeIcon" size="12" />
                          <span class="ml-1"
                            >{{ traveler.membership_company }}:
                            {{ traveler.membership_number }}</span
                          >
                        </div>
                        <br />
                        <div v-if="traveler.has_allergies">
                          <feather-icon
                            icon="HeartIcon"
                            size="12"
                            class="pink"
                          />
                          <span class="ml-1"
                            >Allergie: {{ traveler.allergies }}</span
                          >
                        </div>
                        <div v-if="traveler.prior_medical_condition">
                          <feather-icon
                            icon="HeartIcon"
                            size="12"
                            class="pink"
                          />
                          <span class="ml-1">Condition pré-existante</span>
                        </div>
                      </b-card-text>
                    </div>
                  </b-col>
                  <b-col cols="12" md="6" lg="3">
                    <div>
                      <h5 class="mb-75">
                        <feather-icon icon="BookIcon" size="16" />
                        Contact
                      </h5>
                      <b-card-text>
                        <feather-icon icon="MailIcon" size="12" />
                        <span class="ml-1">{{ traveler.email }}</span>
                        <br />
                        <div style="margin-top: 0.2em">
                          <feather-icon icon="PhoneIcon" size="12" />
                          <span class="ml-1">{{
                            traveler.phone | VMask("(###) ###-####")
                          }}</span>
                        </div>
                        <br />
                      </b-card-text>
                    </div>
                  </b-col>
                  <b-col cols="12" md="6" lg="3">
                    <div>
                      <h5 class="mb-75">
                        <feather-icon icon="MapPinIcon" size="16" />
                        Adresse
                      </h5>
                      <b-card-text>
                        <i>
                          <template v-if="!isGroupX">
                            <span>{{ traveler.address }}</span
                            >, <span>{{ traveler.city }}</span>
                            <br />
                            <span>{{ traveler.province }}</span
                            >, <span>{{ traveler.country }}</span>
                            <br />
                          </template>
                          <span>{{ traveler.postal_code }}</span>
                        </i>
                      </b-card-text>
                      <br />
                    </div>
                  </b-col>
                  <b-col cols="12" md="6" lg="3">
                    <div>
                      <h5 class="mb-75">
                        <feather-icon icon="AlertCircleIcon" size="16" />
                        Contact d'urgence
                      </h5>
                      <b-card-text>
                        <span>{{ traveler.emergency_contact_name }}</span>
                        <br />
                        <span>{{ traveler.emergency_contact_phone }}</span>
                      </b-card-text>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <b-row>
        <b-col>
          <h4 class="text-secondary">Informations supplémentaires</h4>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12" md="6">
          <div>
            <h5 class="mb-75">Information pour la réservation</h5>
            <b-card-text v-if="currentReservation.extra_information">
              {{ currentReservation.extra_information }}
            </b-card-text>
            <b-card-text v-else> Aucune </b-card-text>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div>
            <h5 class="mb-75">Note</h5>
            <b-card-text>
              {{
                currentReservation.notes ? currentReservation.notes : "Aucune"
              }}
            </b-card-text>
          </div>
        </b-col>
      </b-row>

      <b-row
        v-if="
          showAdditionalOptions &&
          reservationOptions &&
          reservationOptions.length > 0
        "
        class="mt-1"
      >
        <b-col>
          <h4 class="text-secondary">Options supplémentaires choisies</h4>
        </b-col>
      </b-row>

      <b-row v-if="reservationOptions && reservationOptions.length > 0">
        <b-col cols="12">
          <b-list-group
            class="mt-1"
            v-for="section in reservationOptions"
            :key="section.id"
          >
            <b-list-group-item class="dark-line-item">
              {{ section.title }}
            </b-list-group-item>

            <div v-for="question in section.questions" :key="question.id">
              <b-list-group-item>{{ question.title }}</b-list-group-item>
              <template v-if="question.answers.length > 0">
                <template v-if="question.is_traveler_specific">
                  <b-list-group-item
                    v-for="answer in question.answers"
                    :key="answer.id"
                    class="d-flex flex-row justify-content-between"
                  >
                    <span class="pl-2 font-weight-bold">{{
                      getTravelerName(answer)
                    }}</span>
                    {{ getAnswerText(answer, question) }}
                  </b-list-group-item>
                </template>
                <template v-else>
                  <b-list-group-item
                    v-for="answer in question.answers"
                    :key="answer.id"
                    class="d-flex flex-row justify-content-between"
                  >
                    <div>{{ getAnswerText(answer, question) }}</div>
                  </b-list-group-item>
                </template>
              </template>
              <b-list-group-item
                v-else
                class="d-flex flex-row justify-content-between"
              >
                N/D
              </b-list-group-item>
            </div>
          </b-list-group>
        </b-col>
      </b-row>

      <!-- <div v-for="(room, index) in getRoomsPricings" :key="index">
          <b-list-group-item> Chambre #{{ index + 1 }} </b-list-group-item>
          <b-list-group-item v-for="pax in room" :key="pax.index">
            {{ t(`reservations['${pax.type}']`) }} #{{ pax.index + 1 }}
            <span style="float: right">${{ pax.price }}</span>
          </b-list-group-item>
        </div>
      </b-list-group>
      <b-list-group class="mt-1">
        <b-list-group-item class="dark-line-item"> Dépots </b-list-group-item>
        <template
          v-if="detailledDeposits.value && detailledDeposits.value.deposits"
        >
          <b-list-group-item
            v-for="paxDeposit in detailledDeposits.value.deposits"
            :key="paxDeposit.index"
          >
            {{ paxDeposit.travelerName }} ({{
              t(`reservations['${paxDeposit.type}']`)
            }})
            <span style="float: right">${{ paxDeposit.amount }}</span>
          </b-list-group-item>
        </template>
        <b-list-group-item>
          FICAV
          <span style="float: right"
            >${{
              FICAVamount.value ? FICAVamount.value.toFixed(2) : "0.00"
            }}</span
          >
        </b-list-group-item>
        <b-list-group-item v-if="detailledDeposits.value">
          Total - Payable aujourd'hui
          <span style="float: right"
            >${{
              (detailledDeposits.value.totalAmount + FICAVamount.value).toFixed(
                2
              )
            }}</span
          >
        </b-list-group-item> -->

      <!-- Not for Transat
      <b-row>
        <b-col >
          <h4 class="text-secondary">Assurances & détails</h4>
        </b-col>
      </b-row>
      <b-row class="mt-1" v-if="currentReservation.has_trip_insurance">
          <b-col cols="3">
            <div>
              <h5 class=" mb-75">
                Assurance du client
              </h5>
              <b-card-text>
                <ul v-for="type in currentReservation.insurance_types" :key="type">
                  <li >{{(type)}}</li>
                </ul>
              </b-card-text>
            </div>
          </b-col>
          <b-col cols="3">
            <div>
              <h5 class=" mb-75">
                Compagnie d'assurance
              </h5>
              <b-card-text>
                {{currentReservation.insurer_name}}
              </b-card-text>
            </div>
          </b-col>
          <b-col cols="3">
            <div>
              <h5 class=" mb-75">
                Numéro de contrat
              </h5>
              <b-card-text>
                {{currentReservation.insurance_contract_number}}
              </b-card-text>
            </div>
          </b-col>
      </b-row>

      <b-row class="mt-3" v-else>
          <b-col cols="3">
            <div>
              <h5 class=" mb-75">
                Assurance du client
              </h5>
              <b-card-text>
                Aucune
              </b-card-text>
            </div>
          </b-col>

          <b-col cols="6">
            <div>
              <h5 class=" mb-75">
                Est-ce que le client désire une soumission?
              </h5>
              <b-card-text>
                {{currentReservation.wants_insurance_submission?"Oui":"Non"}}
              </b-card-text>
            </div>
          </b-col>

      </b-row>

      <b-row v-if="currentReservation.has_membership" class="mt-3">
          <b-col cols="3">
            <div>
              <h5 class=" mb-75">
                Nom de la compagnie associée au numéro
              </h5>
              <b-card-text>
                {{ currentReservation.membership_company }}
              </b-card-text>
            </div>
          </b-col>
          <b-col cols="3">
            <div>
              <h5 class=" mb-75">
                Numéro de membre
              </h5>
              <b-card-text>
                {{ currentReservation.membership_number }}
              </b-card-text>
            </div>
          </b-col>


      </b-row>
      <b-row v-else class="mt-3">
          <b-col cols="3">
            <div>
              <h5 class=" mb-75">
                Numéro de membre
              </h5>
              <b-card-text>
              Aucun
              </b-card-text>
            </div>
          </b-col>
      </b-row>
    -->
    </b-card>
  </div>
</template>

<script>
import {
  BListGroup,
  BListGroupItem,
  BTabs,
  BAlert,
  BTab,
  BRow,
  BCol,
  BCard,
  BCardText,
  BButton,
  BAvatar,
  BSpinner,
} from "bootstrap-vue";
import Tenant from "@/plugins/tenant";
import { useLocalisation } from "@/shared/composables/use-localisation";
import { useApplicationContext } from "@/shared/composables/use-application-context";
import { computed, ref, onMounted, reactive } from "@vue/composition-api";

import { GroupReservationService } from "@/views/groups/services/GroupReservationService.js";
import { GroupAdditionalOptionService } from "@/views/groups/services/GroupAdditionalOptionService.js";

import ReservationSummaryEmailModal from "@/views/groups/group/GroupReservations/EditReservation/ReservationSummaryEmailModal.vue";
import { useToastNotification } from "@/shared/composables/use-toast-notifications";
import { AUTH_STORE_NAMESPACE } from "@/modules/authnz/models/store";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import dayjs from "dayjs";

export const groupReservationService = new GroupReservationService();
export const additionalOptionService = new GroupAdditionalOptionService();

export default {
  components: {
    BTabs,
    BAlert,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BAvatar,
    BSpinner,
    ReservationSummaryEmailModal,
    FeatherIcon,
    BListGroup,
    BListGroupItem,
  },
  props: [
    "currentPackages",
    "currentReservation",
    "currentAgent",
    "currentGroupId",
    "hasExceededInventory",
    "errors",
    "paymentEnabled",
  ],
  setup(props, context) {
    const publicAccessToken = context.root.$route.query.token;
    const { t } = useLocalisation();

    let isTransat = true;
    const { store, forceUpdate } = useApplicationContext();
    let basicInformation = reactive({});
    const agencies = ref({});
    const reservationOptions = ref([]);

    const fetchAgencies = async () => {
      try {
        const response = await groupReservationService.getAllAgencies();
        agencies.value = response.data;
      } catch (error) {
        console.error(error);
      }
    };
    const fetchGroupBasicInformation = async (id) => {
      try {
        forceUpdate();
      } catch (e) {}
    };

    const getPackage = (reservation) => {
      let packageName = "N/A";
      try {
        packageName = reservation?.package_reservations[0]?.group_package?.name;
        return packageName;
      } catch {
        return packageName;
      }
    };

    const showAdditionalOptions = computed(() => {
      return store.state[AUTH_STORE_NAMESPACE].orgSettings
        ?.additional_options_enable;
    });

    const hasPrimaryContact = computed(() => {
      let reservationOwner = false;
      for (let i in props.currentReservation.package_reservations) {
        const element = props.currentReservation.package_reservations[i];
        reservationOwner = element.travelers.find((traveler) => {
          return traveler.reservation_owner === true;
        });

        if (reservationOwner) {
          break;
        }
      }

      if (reservationOwner) {
        return true;
      } else {
        return false;
      }
    });

    const isGroupX = computed(() => {
      const tenantId = Tenant.resolveTenantId();
      return tenantId === "transat";
    });

    const getNbRooms = (reservation) => {
      try {
        return reservation.package_reservations.length;
      } catch {
        return "N/A";
      }
    };

    const hasOptions = (package_reservation) => {
      try {
        let array = [
          package_reservation.extra_bed,
          package_reservation.playpen,
          package_reservation.birthday_celebration,
          package_reservation.honeymoon,
          package_reservation.reduced_mobility,
          package_reservation.requires_wheelchair_assistance,
        ];
        let hasAtLeastOneOption = false;

        array.forEach((value) => {
          if (value) {
            hasAtLeastOneOption = true;
          }
        });

        return hasAtLeastOneOption;
      } catch (error) {
        console.error(error);
        return false;
      }
    };

    const fetchAgencyName = (reservation) => {
      let reservationOwner = reservation.owner;
      let agencyId = reservationOwner?.substring(3, reservationOwner.length);
      try {
        if (agencies.value && Array.isArray(agencies.value)) {
          let agency = agencies.value?.find((element) => {
            return element.id === agencyId;
          });

          if (agency) {
            return agency.name;
          }
        }
      } catch (e) {
        console.error(e);
      }

      return store.state[AUTH_STORE_NAMESPACE].orgSettings.name;
    };

    onMounted(() => {
      fetchGroupBasicInformation(props.currentGroupId);
      fetchAgencies();

      if (
        showAdditionalOptions.value &&
        props.currentReservation &&
        props.currentReservation.id
      ) {
        fetchReservationOptions(
          props.currentGroupId,
          props.currentReservation.id
        );
      }
    });

    const fetchReservationOptions = async (groupId, reservationId) => {
      if (props.currentGroupId != null && props.currentReservation.id != null) {
        try {
          const { data } = await additionalOptionService.getReservationOptions(
            groupId,
            reservationId
          );
          reservationOptions.value = data;
          forceUpdate();
        } catch (e) {
          console.error(e);
          displayErrorMessage(
            "Une erreur est arrivée en essayant de retrouver les informations d'options supplementaires de la réservation."
          );
        }
      }
    };

    const formatDateFromArray = (dateArray) => {
      if (!Array.isArray(dateArray)) {
        return dateArray;
      }
      dateArray = [dateArray[0], dateArray[1], dateArray[2]];
      if (dateArray && dateArray.length == 3) {
        //Add O if single Digit Month

        if (dateArray[1].toString().length == 1) {
          dateArray[1] = "0" + dateArray[1].toString();
        }
        //Add O if single Digit Day
        if (dateArray[2].toString().length == 1) {
          dateArray[2] = "0" + dateArray[2].toString();
        }

        return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
      } else {
        return null;
      }
    };

    const { displaySuccessMessage, displayErrorMessage } =
      useToastNotification();

    return {
      publicAccessToken,
      t,
      getPackage,
      getNbRooms,
      hasPrimaryContact,
      isTransat,
      hasOptions,
      basicInformation,
      formatDateFromArray,
      agencies,
      fetchAgencies,
      fetchAgencyName,
      displayErrorMessage,
      displaySuccessMessage,
      isGroupX,
      reservationOptions,
      fetchReservationOptions,
      showAdditionalOptions,
    };
  },
  methods: {
    getAnswerPrice(price) {
      let value = 0.0;
      if (price) {
        value = price / 100;
      }
      return value.toFixed(2);
    },
    getTravelerName(answer) {
      const traveler =
        this.currentReservation.package_reservations[0].travelers.find(
          (x) => x.id === answer.traveler_id
        );
      return traveler.first_name + " " + traveler.last_name;
    },
    getAnswerText(answer, question) {
      let text = null;
      if (question.choice_type === "text") {
        text = answer.text_value;
      } else {
        let choice = question.choices.find((x) => x.id === answer.choice_id);
        text = choice.title;
        if (choice.price) {
          text += ` (${this.getAnswerPrice(choice.price)} $)`;
        }
      }

      return text ? text : "N/D";
    },
    async print() {
      // Pass the element id here
      await this.$htmlToPaper("printMe");
    },
    getBirthday(date) {
      if (Array.isArray(date)) {
        return `${date[2]}/${date[1]}/${date[0]}`;
      }
      return dayjs(date).format("DD/MM/YYYY");
    },
    showReservationSummaryEmailModal() {
      this.$nextTick(() => {
        this.$refs["res-summary-email-modal"].$children[0].show();
      });
    },
    async sendSummaryEmail(email) {
      try {
        await groupReservationService.sendUserSummaryEmail(
          this.currentGroupId,
          this.currentReservation.id,
          email,
          this.publicAccessToken
        );
        this.displaySuccessMessage(
          "Sommaire de réservation envoyé avec succès"
        );
        this.$refs[
          "res-summary-email-modal"
        ].$refs.summaryEmailModalReservation.hide();
      } catch (e) {
        this.displayErrorMessage("L'envoi du sommaire a échoué");
      }
    },
    fetchAgency(reservation) {
      let reservationOwner = reservation.owner;
      let agencyId = reservationOwner?.substring(3, reservationOwner.length);
      try {
        if (this.agencies) {
          let agency = this.agencies?.find((element) => {
            return element.id === agencyId;
          });

          if (agency) {
            return agency.name;
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    fetchAgencyPhone(reservation) {
      let reservationOwner = reservation.owner;
      let agencyId = reservationOwner?.substring(3, reservationOwner.length);
      try {
        let agency = this.agencies?.find((element) => {
          return element.id === agencyId;
        });
        return agency.phone_number;
      } catch {
        return "";
      }
    },
  },
  computed: {
    currentAgentNameAndAgency() {
      if (!this.currentAgent) {
        return "---";
      }

      let name = this.currentAgent.full_name;
      if (this.currentAgent.agency) {
        name += ` (${this.currentAgent.agency.name})`;
      }
      return name;
    },
    currentAgentPhoneNumber() {
      return this.currentAgent?.phone_number;
    },
    currentAgentEmail() {
      return this.currentAgent?.email;
    },
  },
};
</script>

<style>
.pink {
  color: #f78b99;
}

.blue {
  color: #5bbaea;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
</style>
